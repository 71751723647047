tspan {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: -0.2px;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.45;
}
.tooltipDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px !important;
  gap: 10px;
  width: auto;
  height: 74px;
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  border: none !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.2px;
  color: #222222 !important;
  mix-blend-mode: normal;
}

.recharts-cartesian-axis-tick-line {
  stroke: none;
}

.dateTool {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  letter-spacing: -0.2px;

  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.65;
}

.chartArea {
  max-width: 1046;
  max-height: 251px;
}

@media screen and (max-width: 800px) {
  .chartWrapper {
    padding: 24px 10px !important;
  }
}
