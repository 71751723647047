/* @import "compass/css3"; */

.shadow-\[0_2px_13px_0px_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1000;
  height: 60px;
  /* background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1); */
}
.sm\:shadow-none {
  height: 60px;
}

.line {
  display: flex;
  border-bottom: 1px solid #caccd2;
}

.breadCrumbTab {
  margin-right: 5px;
  box-shadow: inset -1px 0px 0px #caccd2, inset 0px 1px 0px #caccd2,
    inset 1px 0px 0px #caccd2;
  border-radius: 4px 4px 0px 0px;
  padding: 9px 16px;
  cursor: pointer;
  opacity: 0.6;
}

.breadCrumbTab.selectedTab {
  opacity: 1;
  color: black;
  background-color: #fff;
}

.chartWrapper {
  background: #fff;
  box-shadow: 0px 1px 15px rgba(47, 48, 50, 0.08);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding: 24px 32px;
}

.chartVisual {
  flex: 1;
  font-family: Inter, sans-serif;
  position: relative;
}

.chartVisual .chartMetrics span .lds-ellipsis {
  height: 18px;
}

.chartVisual h3 {
  margin-bottom: 12px;
  font-family: Poppins, sans-serif;
}

.chartMetrics {
  /* position: absolute; */
  top: -3px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-bottom: 12px;
  margin-left: 20px;
  color: #222222;
  font-size: 14px;
}

.lineWrapper {
  margin-top: 10px;
}

.chartMetrics > span {
  font-weight: 400;
}

.chartMetrics > span > span {
  font-weight: 500;
}

.chartMetrics span {
  padding: 3px 0;
}
.chartRates {
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;*/
}

.periodButtons {
  margin-bottom: 30px;
}

.periodButtons span {
  display: inline-block;
  border: 1px solid var(--grey-borders);
  border-radius: 2px 0px 0px 2px;
  padding: 5px 16px;
  font-size: 14px;
  cursor: pointer;
}

.periodButtons span:hover {
  border: 1px solid var(--accent-blue);
  color: var(--accent-blue);
  opacity: 0.6;
}

.periodButtons span.selectedButton {
  border: 1px solid var(--accent-blue);
  color: var(--accent-blue);
}

ul.chartInfoList li {
  background: var(--neutral-background);
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 2px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  transform: scale(0.4);
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(131, 129, 129);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.detailsCard {
  border-color: #e4e5e7;
}

.detailsCard .mb-5 {
  margin-bottom: 1.25rem !important;
}

.nftImage {
  width: 100%;
  height: 100% !important;
  display: block;
}

.blur {
  -webkit-filter: blur(4px); /* Safari 6.0 - 9.0 */
  filter: blur(4px);
}

.blurList {
  -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
  filter: blur(2px);
}

.nftWrapper {
  position: relative;
  width: 128px !important;
  /* height: 128px; */
  border-radius: 6px;
  margin-right: 15px;
}

.loadingWrapper {
  background: linear-gradient(
    312.76deg,
    #efefef 22.7%,
    #dadada 41.09%,
    #f2f2f2 92.17%
  );
  animation: gradient 4s ease infinite;
  background-size: 400% 400%;
  border-radius: 6px;
  transition: all 0.7s ease;
  color: transparent;
  padding: 10px 0 0 0;
}

.rowNftWrapper {
  width: 38px;
  height: 38px;
  padding: 0;
}

.loadingWrapper.rowNftWrapper img {
  position: relative;
  z-index: -2;
  width: 38px;
  height: 38px;
}

.loadingWrapper span,
.loadingWrapper a {
  color: transparent;
}

.loadedWrapper {
  background: transparent;
  transition: all 0.5s ease;
  padding: 10px 0 0 0;
}

.DetailsWrapper .loadedWrapper {
  padding-top: 0;
}

.nftWrapper.loadingWrapper,
.nftWrapper.loadedWrapper {
  padding: 0;
}

.nftWrapper.loadingWrapper::before {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  content: "";
}

.trxHash {
  color: #235ef5;
  width: 100%;
}

.detailsListRow {
  align-items: center;
  /* padding-left: 15px; */
}

.detailsListRow .loadingWrapper {
  width: 60%;
  padding: 3px 0 !important;
}

.detailsListRow .loadedWrapper {
  padding: 3px 0 !important;
}

.chainIconDetails {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.detailsListRow p,
.detailsListRow .statusBar {
  align-items: center;
  display: flex;
  margin-left: 60px;
}

.detailsListRow img {
  max-width: initial;
}

.copyBtnWrapper {
  display: flex;
  align-items: center;
}

.nodeStatus {
  color: var(--green);
  display: flex;
  align-items: center;
}

.nodeStatus .dot {
  width: 6px;
  height: 6px;
  background-color: var(--green);
  border-radius: 50%;
  margin-right: 7px;
}

.nonactive {
  opacity: 0.5;
  pointer-events: none;
  cursor: initial;
}

.imgTableData .lds-ellipsis {
  right: 18px;
}

.methodDataTable {
  background-color: #eeeef2;
  border-radius: 20px;
  font-size: 13px;
  padding: 5px 8px;
}

.valueData {
  cursor: default;
}

.tableWrapper {
  position: relative;
}

.tableWrapper tr .sticky img {
  height: 38px;
}

.chainSwitch {
  display: flex;
}
.chainSwitchBg {
  background-color: red;
}

.scrollTopBtn {
  visibility: hidden;
  position: fixed;
  transform: rotate(-270deg);
  cursor: pointer;
  bottom: 30px;
  z-index: 999;
  right: 21%;
}

.inputWrap {
  margin-bottom: -20px !important;
}

.failValid input {
  border-color: rgb(224, 85, 85);
}

.inputError {
  display: flex;
  align-items: center;

  margin-top: 8px;
}

.inputError img {
  margin-right: 7px;
}

.failValid .inputError {
  color: rgb(224, 85, 85);
  font-size: 12px;
  visibility: visible;
}

.paginatorWraper ul,
.paginatorWraper {
  display: flex;
  align-items: center;
}

.paginatorWraper {
  justify-content: space-between;
  padding: 8px 0;
  margin-bottom: 5px;
  font-size: 14px;
}

.paginatorCenter {
  justify-content: center;
}

.paginatorInnerWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.paginatorItem {
  display: none;
}

.prevControl {
  margin-right: 121px;
}
.super-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #235ef5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.margin-left {
  margin-left: 19px;
}
.paginationControlWraper,
.paginatorWraper .button,
.paginatorLabel {
  padding: 8px 15px 8px 15px;
  cursor: pointer;
  display: flex;
  border: 1px solid var(--grey-borders);
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  color: #62718a;
  /* margin-right: 5px;
  /* margin-left: 5px;  */
}

.paginatorLabel ~ .paginatorLabel {
  display: none;
}
.paginatorLabel {
  position: relative;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
}

.paginatorWraper .button {
  padding: 3.5px 10px;
  /* margin-right: 6px;
  margin-left: 6px; */
  transition: all 0.5s ease;
}

.paginatorWraper .buttonFirst {
  padding: 3.5px 10px;
  margin-right: 3px;
  margin-left: 6px;
  transition: all 0.5s ease;
}

.paginatorWraper .buttonLast {
  padding: 3.5px 10px;
  margin-right: 6px;
  margin-left: 3px;
  transition: all 0.5s ease;
}

.paginationControlWraper:hover,
.paginatorWraper .button:hover {
  background-color: #235ef5;
  color: #fff;
}

.paginationControlWraper:hover img {
  filter: brightness(400%);
}

.paginationControlWraper img {
  margin: auto;
  transform: scale(0.85);
}

.paginate-disabled {
  background-color: light-gray;
  pointer-events: none;
}

/* @media screen and (max-Width:600) {
  .paginatorLabel {
    width:110px;
    padding: 3.8px 2px 3.8px 10px;
    position: absolute;
    right: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
} */

/* .paginatorLabel {
  width:130px;
  padding: 3.8px 2px 3.8px 2px;
  position: absolute;
  left: 61.5%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

.ttip {
  background-color: rgba(0, 0, 0, 1) !important;
  border-radius: 6px !important;
}

.ttip::before,
.ttip::after {
  display: none !important;
}

.leftWrapper {
  display: flex;
}

/* .csvBtn {
  display: flex;
  align-items: center;
  background: rgba(35, 94, 245, 0.1);
  color: #235ef5;
  border-radius: 4px;
  border: 0 !important;
  margin-right: 1px;
}

.csvBtn:hover {
  color: #ffffff !important;
  background: #235ef5;
} */

/* .csvBtn:hover span {
  color: #ffffff !important;
}

.csvBtn:hover svg path {
  fill: #ffffff;
}  */

.csvBtn:hover {
  background: #e4e5e7;
  color: #222222;
}

.csvBtn svg {
  margin-right: 5px;
}

.csvBtn svg path {
  fill: #222222;
}

.explorerCard {
  display: flex;
  flex-direction: column;
  height: 122px;
  /* align-items: flex-start; */
  padding: 1rem 1rem 1rem 2rem !important;
  gap: 16px;
}

.rowLabelCard {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: flex-end;
}

.explorerCard .loadingWrapper,
.chartMetrics .loadingWrapper {
  width: 100px;
  color: transparent;
}

.errorTip {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.tableWrapper .lds-ellipsis.addressLoader {
  width: initial;
  height: initial;
  bottom: 10px;
}
.navLeft {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.navLeft img {
  max-width: max-content !important;
}

.mainnetDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 5px;
  gap: 5px;
  /* width: 84px; */
  height: 20px;
  background: rgba(243, 251, 248, 0.6);
  border-radius: 5px;
  margin-top: 30px;
  /* align-self: flex-end; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #10b67a;
}

.pulse-button {
  position: relative;
  display: block;
  width: 5.6px;
  height: 5.6px;
  border-radius: 50%;
  background: #10b67a;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(18, 220, 39, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(13, 172, 24, 0);
  }
}
/* @-webkit-keyframes pulse {
  0% {
    @include transform(scale(0.9));
  }
  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    @include transform(scale(0.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  z-index: 1000;
}

.CSVwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CSVwrapper span {
  margin: 0 20px;
}

input.datePick {
  border: 1px solid var(--grey-borders);
  font-family: Inter;
  padding: 5px;
  border-radius: 4px;
}

input.datePick:focus,
input.datePick:active {
  border: 1px solid var(--grey-borders);
  outline: none;
  box-shadow: none;
}

input.datePick,
input.datePick:focus,
input.datePick:active {
  border: none;
  width: 100px !important;
}

.CSVwrapper {
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid var(--grey-borders);
  padding: 5px 15px 5px 15px;
  color: gray;
}

.endDatePick {
  margin-left: 12px;
}

.toolsChainList li {
  display: flex;
  align-items: center;
}

.toolsChainList img {
  width: 24px;
  height: 24px;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.statusWrapper h1 {
  padding: 0 5px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* @media screen and (max-width: 1000px) {
  .ErrorContainer {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
} */
@media screen and (max-width: 1500px) {
  .ErrorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-30%, -30%);
  }
}

@media screen and (max-width: 1000px) {
  .ErrorContainer {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.cont {
  flex-direction: column;
}

.ErrorContainer {
  /* flex-direction: column; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ErrorToolsContainer {
  flex-direction: column;
  margin-top: 23%;
}

.ErrorImage {
  flex-basis: 37%;
}

.ErrorText {
  padding-left: 30px;
  /* padding-bottom: 110px; */
}
