@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@400;500&display=swap&family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown-scrollbar {
  overflow: overlay;
}


.dropdown-scrollbar::-webkit-scrollbar {
  @apply w-1 bg-transparent;
}
table {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; /* for newer versions of iOS */
}
/* Track */
::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c8ccd6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #0000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-[#E0E0E3] rounded-md;
}

.active {
  @apply border-red-600;
}

body {
  @apply bg-[#F7F7F9];

  --accent-blue: #235ef5;
  --grey-borders: #d9d9d9;
  --neutral-background: #f7f7f9;
  --green: #04b200;
}

.mobileOnly {
  display: none;
}

.wave {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  background: #18bd1e;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wave:after {
  z-index: -1;
  content: "";
  width: 6px;
  height: 6px;
  background: #18bd1e;
  border-radius: 50%;
  @apply animate-ping;
  animation-duration: 4s;
}

.copyTip {
  border-radius: 5px !important;
  background: rgba(0, 0, 0, 0.9);
  padding: 5px 6px !important;
}

.copy-btn:hover img {
  filter: brightness(15%);
}

.copyTip.copied {
  padding-left: 20px !important;
}

.copyTip.copied::before {
  content: "" !important;
  position: absolute !important;
  left: 12px !important;
  top: 40% !important;
  transform: translateY(-60%) !important;
  width: 18px !important;
  height: 18px !important;
  background: url("./assets/icons/check.svg") center no-repeat !important;
  background-size: cover !important;
}

.clearWrapper {
  margin-right: 20px;
  position: relative;
  display: flex;
}

.clearWrapper img {
  margin: auto 0;
}

.clearWrapper::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 1px;
  background: rgb(240, 239, 237);
  right: -12px;
  top: 0;
}

.searchIcon {
  /* cursor: pointer; */
}

.searchIcon:hover {
  filter: brightness(86%);
}

.infoTextWrap {
  margin-left: 35px;
}

.infoTextContainer {
  padding: 10px 0 !important;
  justify-content: space-between;
}

.infoTextContainer.longCol {
  margin-left: -10px;
}

.formSuccess,
.formFail {
  display: flex;
  align-items: center;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  padding: 14px 18px;
  background-color: #0cc13f;
  color: #fff;
  font-size: 14px;
  font-family: "Inter";
  border-radius: 6px;
}

.formFail {
  background-color: #fb5632;
}

.formFail span {
  font-size: 14px;
  color: #fff;
}

.formSuccess img,
.formFail img {
  margin-right: 0;
  margin-left: 12px;
  cursor: pointer;
}

.formSuccess img:first-child,
.formFail img:first-child {
  margin-left: 0;
  margin-right: 16px;
  cursor: default;
}

.formSuccess pre,
.formFail pre {
  color: #f7f7f9;
  margin-left: 10px;
  opacity: 0.7;
}

.formFail {
  color: #c93d49;
  font-size: 12px;
}

#captchaContainer {
  margin-top: 20px;
}

.ageHeader {
  display: flex;
  align-items: center;
}

.ageHeader img {
  margin-left: 10px;
  cursor: pointer;
}

.ageHeader img.rotated {
  transform: rotate(180deg);
}

.chainIcon {
  width: 22px;
  height: 22px;
}

.chartLoaderWrap {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsLetterWrapper .inputWrapper {
  margin-top: 1px;
  position: relative;
  display: inline-block;
}

.newsLetterWrapper .inputWrapper img {
  position: absolute;
  right: 8px;
  transform: translateY(50%);
  cursor: pointer;
}

.newsLetterWrapper .inputWrapper img:hover {
  opacity: 0.7;
}

.mobileOnly .widget_link {
  margin-top: 25px;
}

.newsLetterWrapper input {
  border-radius: 8px;
  border: 1px solid#3F3F42;
  background-color: #2b2b2c;
  color: #fff;
  padding: 10px 15px;
  transition: all 0.3s ease;
  padding-right: 32px;
  width: 205px;
  font-size: 12px;
  margin-bottom: 15px;
}

.newsLetterWrapper input:focus {
  outline: none;
}

.newsLetterWrapper h4 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.newsLetterWrapper p {
  color: #ffffff;
  font-size: 14px;
  margin-top: 10px;
  opacity: 0.7;
}

.text-white {
  /* --tw-text-opacity: 1 !important; */
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.newsLetterWrapper input:focus,
.newsLetterWrapper input:active {
  border: 1px solid #fff;
}

input.failedEmail {
  border-color: rgb(218, 109, 90);
}

.py-4 {
  padding-top: 1.2rem !important;
  padding-bottom: 1rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2b2b2c inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgb(241, 238, 238) !important;
}

.quick_links p {
  margin-bottom: 10px;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.rounded-lg{
  height: 38px !important;
}
