.arrow-down {
    /* margin: auto; */
    /* flex: 1; */
    width: 21px;
    height: 10px;
    /* margin-right: 3px; */
    background-image: url("../../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.arrow-downTo {
    margin: auto;
    width: 21px;
    height: 10px;
    margin-right: 2px;
    background-image: url("../../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.chain-switch {
    flex: 1;
    /* display: table; */
    /* margin: auto;
  margin-right: 1px;
  margin-left: 2px;
  width: 7rem;
  max-width: 7rem !important; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    background: white;
    color: #14161a;
    border: 1px solid white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    padding: 2px;
    border-radius: 4px;
    box-shadow: 0px 1px 15px rgb(47 48 50 / 8%);
    cursor: pointer;
}

#dropdown-basic-button {
    border: 0px;
    outline: 0px;
}

#dropdown-basic-button:hover {
    border: 0px;
    outline: 0px;
}

.dropdown:hover .dropdown-toggle::after {
    color: #235ef5 !important;
}

.clearFiltersDesk {
    display: flex;
    align-items: center;
    background: rgba(35, 94, 245, 0.1);
    color: #235ef5;
    border-radius: 4px;
    border: 0 !important;
    margin-right: 1px;
    padding: 5px 8px;
    margin-left: 5px;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
}

.clearFiltersDesk:hover {
    background: #235ef5;
    color: #ffffff;
}

.nothing {
    flex: 12;
}

.nameWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 2px;
}

.typeBtnWidth {
    width: auto;
}

.navMarginTop {
    margin-top: 25px;
}

.statusTitleFont {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.chainIconDropd {
    width: 15px;
    height: 15px;
}

.name {
    width: 100%;
    margin-left: 2px;
}

.modalBtnWrapper {
    display: flex;
    /* border-radius: 4px;
  background: #eeeef2; */
}

.csvModal {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 14px rgb(47 61 84 / 35%);
    left: 50%;
    padding: 40px 30px;
    position: fixed;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.csvModal h2 {
    position: absolute;
    top: 15px;
}
.csvModal .divider {
    background-color: var(--grey-borders);
    height: 1px;
    margin-top: 10px;
}
.modalText {
    font-size: 14px;
    margin-bottom: 12px;
}
.csvBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 0px;
    height: 32px;
    background: #eeeef2;
    border: 1px solid #ebecef;
    border-radius: 4px;
    margin-right: 1px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #222222;
}

.filterBtnModal {
    background-color: #235ef5;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 16px;
}

.clearFilterBtnModal {
    padding: 5px 16px;
    background-color: #eeeef2 !important;
    color: #222222 !important;
}

.filterBtnsWrapper {
    display: flex;
    flex-direction: row;
    width: inherit;
    justify-content: space-between;
    margin-top: 32px;
}

img.closeModal {
    color: #000;
    cursor: pointer;
    -webkit-filter: brightness(15%);
    filter: brightness(15%);
    font-size: 15px;
    position: absolute;
    right: 25px;
    top: 20px;
    z-index: 999;
}
/* 
.CSVwrapper {
  border: 1px solid var(--grey-borders);
  border-radius: 4px;
  color: gray;
  margin-bottom: 12px;
  padding: 5px 15px;
} */
.bg-black {
    --bs-bg-opacity: 0 !important;
}

@media screen and (max-width: 639px) {
    .navMarginTop {
        margin: unset !important;
    }
}
